<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      v-model="itemSelected"
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th
                    ><vs-checkbox
                      v-model="selectAll"
                      @input="handleSelectAll"
                    />ALL</vs-th
                  > -->

        <vs-th>Select</vs-th>
        <vs-th sort-key="code">POD Data</vs-th>
        <vs-th sort-key="code">Shipment Data</vs-th>
        <vs-th sort-key="name">Driver</vs-th>
        <vs-th sort-key="name">Customer Data</vs-th>
        <!-- <vs-th sort-key="name">SO Data</vs-th> -->
        <vs-th sort-key="name">DO/DN Data</vs-th>
        <!-- <vs-th sort-key="name">List Item</vs-th> -->
        <vs-th sort-key="name">Payment Term</vs-th>
        <vs-th sort-key="name">Source</vs-th>
        <vs-th sort-key="name">POD Status</vs-th>
        <vs-th sort-key="name">Document</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <feather-icon
              icon="EyeIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-4"
              @click="handleEdit(tr)"
            />
          </vs-td>
          <vs-td>
            <ol class="bullet-list">
              <li>
                <b>POD Code :</b>
                {{ tr.pod_code }}
              </li>
              <li>
                <b>GI Number: </b>
                {{ tr.gi_number }}
              </li>
              <li>
                <b>Invoice Code: </b>
                {{ tr.invoice_code }}
              </li>
            </ol>
          </vs-td>

          <vs-td>
            <ol class="bullet-list">
              <li>
                {{ tr.shipment_number }}
              </li>
              <li>
                {{ tr.delivery_date }}
              </li>
            </ol>
          </vs-td>
          <vs-td>
            <ol class="bullet-list">
              <li>
                {{ tr.driver_name }}
              </li>
              <li>
                {{ tr.vehicle_number }}
              </li>
            </ol>
          </vs-td>
          <vs-td>
            <ol class="bullet-list">
              <li>
                {{ tr.customer_code }}
              </li>
              <li>
                {{ tr.customer_name }}
              </li>
              <li>
                {{ tr.zone_name }}
              </li>
            </ol>
          </vs-td>
          <!-- <vs-td>
            <ol>
              <li>
                {{ tr.sales_order_code }}
              </li>
            </ol>
          </vs-td> -->
          <vs-td>
            <ol class="bullet-list">
              <li>{{ tr.delivery_order_code }}</li>
              <li>{{ tr.customer_name }}</li>
            </ol>
          </vs-td>
          <!-- <vs-td>
            <ol v-for="(item, index) in tr.items_pod" :key="index">
              <li>{{ index + 1 }}. {{ item.item_name }}</li>
            </ol>
          </vs-td> -->
          <vs-td>
            {{ tr.payment_term }}
          </vs-td>
          <vs-td>
            {{ tr.notes }}
          </vs-td>
          <vs-td>
            {{ tr.status_delivery ? tr.status_delivery : "" }}
          </vs-td>
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                :vs-value="tr"
                color="success"
                disabled
                :checked="tr.is_document"
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
    <!-- <div class="vx-row">
      <div class="vx-col sm:w-6/12 w-full">
        <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
          >Confirm</vs-button
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    transportPlanID: {
      type: Number,
      default: null,
    },
    warehouseID: {
      type: Number,
      default: null,
    },
    driverName: {
      type: String,
      default: null,
    },
    dateStart: {
      type: String,
      default: "",
    },
    dateEnd: {
      type: String,
      default: "",
    },
    selectedStatus: {
      type: Object,
      default: null,
    },
    isFilters: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    // this.getWarehouse();
    // this.getShipmentNumber();
  },

  data() {
    return {
      itemSelected: [],
      deleteId: null,
      table: this.tableDefaultState(),
      transportPlan: [],
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      cartPODConfirm: [],
      cartTransportPlan: [],
      IDItem: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "updated_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getDatas();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDatas();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getDatas();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getDatas();
    },

    getDatas() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/proof-of-delivery/pod-table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: "updated_at",
            sort: this.table.sort,
            transport_plan_id: this.transportPlanID,
            warehouse_id: this.$store.getters["pod/pod/getWarehouseID"],
            date_from: this.dateStart,
            date_to: this.dateEnd,
            driver_name: this.driverName ? this.driverName : null,
            status_delivery: this.selectedStatus
              ? this.selectedStatus.id
              : null,
            status: 3,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.table.data = [];
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;

              for (let index = 0; index < resp.data.records.length; index++) {
                this.table.data[index]["indextr"] = index;
                this.table.data[index]["source"] = "Manual";
              }
              this.$emit("isFilters", false);
              this.setStartEnd();
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartPODConfirm.id);
      this.$emit("selected-items", this.cartPODConfirm);
    },

    SubmitForm() {
      console.log(">>>>>ini submit", this.itemSelected);
      if (this.itemSelected.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      for (let index = 0; index < this.itemSelected.length; index++) {
        let items = {};
        items.id = this.itemSelected[index].pod_id;
        items.status = 3;

        this.IDItem.push(items);
      }
      for (let index = 0; index < this.IDItem.length; index++) {
        if (this.IDItem[index].id == 0) {
          this.$vs.notify({
            title: "Error",
            text: "You Have delivery order does't set STATUS POD",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.itemSelected = [];
          this.IDItem = [];
          return true;
        }
      }
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/proof-of-delivery/process", {
          pod_process: this.IDItem,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Process Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getDatas();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    handleEdit(data) {
      console.log("data", data);
      this.$vs.loading();
      this.$router.push({
        name: "proof-of-delivery-show",
        query: {
          id: data.id,
          action: "Show",
        },
      });
      this.$vs.loading.close();
    },
  },
  watch: {
    detail() {},
    isFilters(val) {
      if (val) {
        this.getDatas();
        this.$emit("isFilters", false);
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>
<style>
.bullet-list {
  list-style-type: disc; /* Atur tipe tanda (dalam hal ini tanda titik) */
  padding-left: 20px; /* Atur jarak kiri untuk membuat tanda tampak lebih baik */
}
</style>
